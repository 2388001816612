import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaLocationArrow, FaPhoneSquare, FaEnvelope } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Гольф-клуб G-Stream
			</title>
			<meta name={"description"} content={"Де кожен удар розповідає історію"} />
			<meta property={"og:title"} content={"Гольф-клуб G-Stream"} />
			<meta property={"og:description"} content={"Де кожен удар розповідає історію"} />
			<meta property={"og:image"} content={"https://wilonfisplad.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wilonfisplad.com/img/5147640.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wilonfisplad.com/img/5147640.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="68px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-green"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-23"
		>
			<Override slot="SectionContent" max-width="1220px" align-items="center" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--light"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Контакти
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
				max-width="1000px"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="rgba(25, 30, 34, 0.59)"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						margin="0px 0px 16px 0px"
						color="--light"
						size="64px"
						category="fa"
						icon={FaLocationArrow}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 12px 0px" color="--light" text-align="center">
						Адреса
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						font="--base"
						text-align="center"
					>
						Київська 17-а, с.Гавронщина, 08003
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="rgba(25, 30, 34, 0.59)"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						icon={FaPhoneSquare}
						margin="0px 0px 16px 0px"
						color="--light"
						size="64px"
						category="fa"
						lg-font="48px sans-serif"
					/>
					<Text color="--light" font="--lead" margin="0px 0px 12px 0px" text-align="center">
						Телефон
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						text-align="center"
					>
						067 403 15 41
					</Text>
				</Box>
				<Box
					display="flex"
					background="rgba(25, 30, 34, 0.59)"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="6px"
				>
					<Icon
						size="64px"
						category="fa"
						icon={FaEnvelope}
						margin="0px 0px 16px 0px"
						color="--light"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 12px 0px" color="--light" font="--lead" text-align="center">
						Email
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--light"
						font="--base"
						text-align="center"
					>
						info@wilonfisplad.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});